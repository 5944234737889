import type { FormPostSubmitFields } from "../../translation-keys/post-submit-text-keys.js";

const BlindHeading ="Welcome Back!";
const BlindSubmitBody ="Click the button below to download this asset.";
const Heading ="Thank you for your interest!";
const Body ="We have sent an email to the provided address with a download link to the requested asset. You may also click the button below to start your download.";
const Download = "Télécharger";

const PostFormFieldMap: FormPostSubmitFields = {
  "document-BlindSubmitHeading": "Nous vous remercions de l'intérêt que vous portez à notre contenu. Cliquez sur le document de gauche pour lancer le téléchargement.",
  "document-BlindSubmitBody": "Vous avez désormais accès à tous les documents et vidéos exclusifs de MasterControl.",
  "document-Heading": "",
  "document-Body": "Je vous remercie! Les ressources ont été envoyées à votre adresse e-mail, mais vous pouvez également les télécharger dès maintenant. N'hésitez pas à continuer à explorer notre site pour en savoir plus sur MasterControl et sur nos solutions.",
  "document-Download": Download,
  "document-landing-page-BlindSubmitHeading": "Nous vous remercions de l'intérêt que vous portez à notre contenu. Cliquez sur le bouton ci-dessous pour lancer le téléchargement.",
  "document-landing-page-BlindSubmitBody": "Vous avez désormais accès à tous les documents et vidéos exclusifs de MasterControl.",
  "document-landing-page-Heading": "Nous vous remercions de l'intérêt que vous portez à notre contenu. Cliquez sur le bouton ci-dessous pour lancer le téléchargement.",
  "document-landing-page-Body": "",
  "document-landing-page-Download": Download,
  "post-event-assets-BlindSubmitHeading": BlindHeading,
  "post-event-assets-BlindSubmitBody": BlindSubmitBody,
  "post-event-assets-Heading": Heading,
  "post-event-assets-Body": Body,
  "post-event-assets-Download": Download,
  "ppc-BlindSubmitHeading": BlindHeading,
  "ppc-BlindSubmitBody": BlindSubmitBody,
  "ppc-Heading": "Nous vous remercions de l'intérêt que vous portez à notre contenu. Cliquez sur le bouton ci-dessous pour lancer le téléchargement.",
  "ppc-Body": Body,
  "ppc-Download": Download,
  "case-study-BlindSubmitHeading": BlindHeading,
  "case-study-BlindSubmitBody": BlindSubmitBody,
  "case-study-Heading": "Merci d'avoir rempli le formulaire. Veuillez cliquer sur le bouton de téléchargement ci-dessous pour afficher le document demandé.",
  "case-study-Body": "",
  "case-study-Download": "Télécharger l'étude de cas",
  "video-BlindSubmitHeading": "Nous vous remercions de l'intérêt que vous portez à notre contenu. Voici quelques ressources supplémentaires que vous pourriez trouver utiles.",
  "video-BlindSubmitBody": "Vous avez désormais accès à tous les documents et vidéos exclusifs de MasterControl.",
  "video-Heading": "Je vous remercie! Vos fichiers ont été envoyés.",
  "video-Body": "Les ressources ont été envoyées à votre adresse e-mail, mais vous pouvez également les télécharger dès maintenant. N'hésitez pas à continuer à explorer notre site pour en savoir plus sur MasterControl et sur nos solutions.",
  "video-Download": Download,
  "video-landing-page-BlindSubmitHeading": "Nous vous remercions de l'intérêt que vous portez à notre contenu. Cliquez sur le bouton ci-dessous pour regarder la vidéo.",
  "video-landing-page-BlindSubmitBody": "Vous avez désormais accès à tous les documents et vidéos exclusifs de MasterControl.",
  "video-landing-page-Heading": Heading,
  "video-landing-page-Body": Body,
  "video-landing-page-Download": "Lire la vidéo",
  "recommended-resources-BlindSubmitHeading": "",
  "recommended-resources-BlindSubmitBody": "",
  "recommended-resources-Heading": "Nous vous remercions de l'intérêt que vous portez à notre contenu. Cliquez sur le document de gauche pour lancer le téléchargement.",
  "recommended-resources-Body": "Vous avez désormais accès à tous les documents et vidéos exclusifs de MasterControl.",
  "recommended-resources-Download": "",
  "general-modal-BlindSubmitHeading": "",
  "general-modal-BlindSubmitBody": "",
  "general-modal-Heading": "Ressources demandées",
  "general-modal-Body": "Thank you! You will receive an email with your document link or you can download it now.",
  "general-modal-Download": ""
};
export default PostFormFieldMap;